/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { Box, DropButton } from 'grommet'
import { AppsRounded } from 'grommet-icons'

import useSidebar from 'hooks/useSidebar'
import OrgLogo from 'components/org/OrgLogo'

import DropArrow from './DropArrow'
import DropdownOrgMenu from './DropdownOrgMenu'

export default function OrgMenuButton() {
  const { selectedOrg } = useSidebar()
  const [open, setOpen] = useState(false)

  return (
    <Box justify="center" background={open ? 'background-contrast-tr' : undefined}>
      <DropButton
        reverse
        gap="4px"
        alignSelf="center"
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={
          selectedOrg ? (
            <OrgLogo
              key={selectedOrg.domain}
              imgSize={80}
              size="28px"
              domain={selectedOrg.domain}
            />
          ) : (
            <Box width="28px" height="28px" align="center" justify="center">
              <AppsRounded size="20px" />
            </Box>
          )
        }
        icon={<DropArrow size="20px" open={open} />}
        css={{ paddingLeft: '.65rem', paddingRight: '.5rem' }}
        dropContent={<DropdownOrgMenu />}
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropProps={{
          elevation: 'small',
          overflow: 'visible',
          margin: { top: '1rem' },
          round: { corner: 'bottom', size: 'xsmall' },
        }}
      />
    </Box>
  )
}
