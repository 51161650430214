/** @jsxImportSource @emotion/react */
import { FormDown, IconProps } from 'grommet-icons'

type Props = {
  open: boolean
} & IconProps
export default function DropArrow({ open, ...props }: Props) {
  return (
    <FormDown
      {...props}
      color={open ? 'gray' : 'silver'}
      css={{
        transition: '.2s all',
        transform: open ? 'scale(1.25) translateY(2px)' : 'scale(1) translateY(0)',
      }}
    />
  )
}
