import { PropsWithoutRef, SVGProps } from 'react'

export default function MoodlightLogo(props: PropsWithoutRef<SVGProps<SVGElement>>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 453 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.706 38.175C105.202 36.7227 102.018 34.5938 99.338 31.91L106.256 25C108.029 26.7743 110.133 28.1821 112.45 29.143C114.767 30.1039 117.251 30.599 119.759 30.6C122.267 30.6 124.75 30.106 127.067 29.146C129.384 28.1841 131.489 26.7753 133.262 25L140.179 31.909C137.498 34.5926 134.315 36.7215 130.811 38.174C127.307 39.6262 123.552 40.3738 119.759 40.374C115.966 40.3741 112.21 39.6269 108.706 38.175V38.175Z"
        fill="#1AD2F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.969 27.2C185.473 28.6525 188.656 30.7814 191.337 33.465L184.419 40.375C182.646 38.601 180.541 37.1938 178.224 36.2336C175.907 35.2735 173.424 34.7793 170.916 34.7793C168.408 34.7793 165.925 35.2735 163.608 36.2336C161.291 37.1938 159.186 38.601 157.413 40.375L150.5 33.465C154.537 29.4244 159.682 26.6723 165.284 25.5571C170.886 24.4418 176.692 25.0136 181.969 27.2V27.2Z"
        fill="#FF542E"
      />
      <path
        d="M84.4 86.233C77.31 86.233 75.031 82.858 75.031 76.358V63.358C75.031 57.197 72.837 55.002 68.026 55.002C64.5577 55.1201 61.2293 56.3979 58.573 58.631C58.6744 59.2447 58.7305 59.8651 58.741 60.487V85.727H47.6V63.361C47.6 57.2 45.321 55.005 40.511 55.005C35.531 55.005 31.817 57.959 30.211 59.393V85.727H19.069L19 45.045H30.141L30.21 51.122C32.0387 49.2232 34.2275 47.7079 36.6484 46.6645C39.0693 45.6211 41.6739 45.0705 44.31 45.045C50.64 45.045 54.945 47.493 57.054 51.966C58.849 49.8111 61.0937 48.075 63.6307 46.8794C66.1676 45.6838 68.9355 45.0577 71.74 45.045C81.278 45.045 86.173 50.531 86.173 60.153V74.839C86.173 76.696 86.764 77.371 88.368 77.371C89.7817 77.2485 91.1725 76.9363 92.503 76.443L93.938 83.364C91.1076 85.2323 87.7915 86.2298 84.4 86.233V86.233Z"
        fill="#320064"
      />
      <path
        d="M119.758 86.994C104.735 86.994 97.139 77.371 97.139 66.146C97.139 54.667 104.65 45.046 119.758 45.046C134.782 45.046 142.378 54.668 142.378 66.146C142.378 77.371 134.7 86.994 119.758 86.994ZM131.237 66.061C131.237 59.647 127.524 54.414 119.758 54.414C111.909 54.414 108.28 59.562 108.28 66.061C108.28 72.729 112.247 77.709 119.758 77.709C127.186 77.709 131.237 72.729 131.237 66.061Z"
        fill="#320064"
      />
      <path
        d="M170.916 86.994C155.893 86.994 148.296 77.371 148.296 66.146C148.296 54.667 155.808 45.046 170.916 45.046C185.94 45.046 193.536 54.668 193.536 66.146C193.536 77.371 185.855 86.994 170.916 86.994ZM182.4 66.061C182.4 59.647 178.686 54.414 170.921 54.414C163.072 54.414 159.443 59.562 159.443 66.061C159.443 72.729 163.409 77.709 170.921 77.709C178.344 77.709 182.4 72.729 182.4 66.061Z"
        fill="#320064"
      />
      <path
        d="M240.966 86.233C235.986 86.233 233.454 84.633 232.272 81.169C230.499 83.195 226.28 86.994 218.515 86.994C206.107 86.994 199.609 77.794 199.609 65.723C199.609 53.401 208.049 45.045 220.962 45.045C224.713 44.961 228.417 45.894 231.681 47.745V31.035H242.822V74.835C242.822 76.692 243.329 77.367 244.932 77.367C246.348 77.2562 247.741 76.9437 249.068 76.439L250.587 83.36C247.722 85.2207 244.382 86.218 240.966 86.233V86.233ZM222.566 54.333C215.566 54.333 210.75 58.385 210.75 65.643C210.75 73.662 214.55 77.713 221.975 77.713C223.849 77.7143 225.703 77.3247 227.418 76.5689C229.133 75.8132 230.671 74.708 231.935 73.324V57.284C230.584 56.187 227.8 54.329 222.566 54.329V54.333Z"
        fill="#320064"
      />
      <path
        d="M266.676 86.065C259.418 86.065 257.139 82.689 257.139 76.19V31.035H268.28V74.417C268.28 76.527 268.955 77.203 270.812 77.203C272.784 77.0756 274.737 76.736 276.636 76.19L278.155 83.28C274.612 85.1282 270.672 86.0841 266.676 86.065V86.065Z"
        fill="#320064"
      />
      <path
        d="M289.044 39.559C284.908 39.559 282.038 36.859 282.038 32.976C282.038 29.093 284.908 26.393 289.044 26.393C293.095 26.393 295.965 29.093 295.965 32.976C295.965 36.859 293.1 39.559 289.044 39.559ZM283.473 46.059H294.615V85.559H283.473V46.059Z"
        fill="#320064"
      />
      <path
        d="M314.718 80.157C314.718 82.267 317.925 82.604 322.736 82.604C340.882 82.604 345.018 89.188 345.018 95.604C345.018 103.537 337.928 108.939 322.905 108.939C308.05 108.939 300.623 103.875 300.623 96.447C300.623 91.299 304.59 88.43 308.723 86.91C306.191 85.644 304.672 83.703 304.672 80.833C304.672 77.963 306.951 75.933 309.905 74.587C304.166 71.887 301.127 67.16 301.127 61.167C301.127 52.643 307.373 44.878 322.903 44.878C325.431 44.8751 327.951 45.1582 330.415 45.722C334.215 44.962 335.815 40.152 335.815 31.036H346.2C346.2 40.067 343.161 46.229 337.591 48.929C339.571 50.2912 341.185 52.1206 342.289 54.2553C343.393 56.3899 343.953 58.7639 343.921 61.167C343.921 70.621 336.494 77.12 323.074 77.12H320.8C319.951 77.119 314.718 77.456 314.718 80.157ZM311.848 95.687C311.848 99.231 315.393 101.087 322.905 101.087C330.585 101.087 333.793 99.23 333.793 95.854C333.793 93.323 332.193 90.454 322.736 90.454C320.542 90.454 318.432 90.3697 316.406 90.201C314.549 90.876 311.848 92.4 311.848 95.687ZM322.82 53.823C315.478 53.823 312.355 57.368 312.355 61.673C312.355 66.399 315.815 69.273 322.989 69.273C329.319 69.273 332.695 66.403 332.695 61.673C332.7 57.368 329.572 53.823 322.82 53.823Z"
        fill="#320064"
      />
      <path
        d="M389.837 86.065C382.837 86.065 380.553 82.689 380.553 76.19V63.19C380.553 57.029 378.274 54.834 373.463 54.834C368.484 54.834 364.77 57.788 363.163 59.223V85.558H352.025V31.035H363.166V50.954C364.995 49.0555 367.184 47.5403 369.605 46.4969C372.025 45.4535 374.63 44.9028 377.266 44.877C386.803 44.877 391.698 50.363 391.698 59.984V74.671C391.698 76.527 392.205 77.203 393.808 77.203C395.224 77.092 396.617 76.7792 397.944 76.274L399.463 83.196C396.596 85.0562 393.254 86.0521 389.837 86.065Z"
        fill="#320064"
      />
      <path
        d="M421.253 86.825C411.969 86.825 406.145 82.689 406.145 72.307V55.507H399.055V45.974H406.145V31.035H417.286V45.974H431.381V55.512H417.286V70.7C417.286 75.005 418.974 76.777 423.194 76.777C425.934 76.8542 428.636 76.1202 430.96 74.667L434.082 83.444C430.198 85.7297 425.759 86.8996 421.253 86.825V86.825Z"
        fill="#320064"
      />
    </svg>
  )
}
