import { Avatar, AvatarExtendedProps } from 'grommet'
import { Organization } from 'grommet-icons'

type Props = AvatarExtendedProps & {
  domain: string | null
  imgSize?: number
}

export default function OrgLogo({ domain, imgSize = 80, ...props }: Props) {
  if (!domain) {
    return (
      <Avatar background="background-contrast" {...props}>
        <Organization />
      </Avatar>
    )
  }
  return (
    <Avatar
      round={{ size: '4px' }}
      size="small"
      src={`https://logo.clearbit.com/${domain}?size=${imgSize}`}
      {...props}
    />
  )
}
