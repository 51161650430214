import { OrganizationWithTeams } from 'db-tables'
import { Box, BoxExtendedProps, Text } from 'grommet'

import useDeviceSize from 'hooks/useDevice'

import OrgLogo from './OrgLogo'

type Props = BoxExtendedProps & { org: OrganizationWithTeams }
export default function DisplayOrg({ org, ...props }: Props) {
  const { fontSize, matchingSize } = useDeviceSize()
  return (
    <Box
      gap={matchingSize}
      key={org.id}
      align="center"
      direction="row"
      pad={{ horizontal: 'xsmall' }}
      {...props}>
      <OrgLogo domain={org.domain} />
      <Text size={fontSize} weight="bold">
        {org.name}
      </Text>
    </Box>
  )
}
