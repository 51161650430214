import { useTranslation } from 'next-i18next'

import { routes } from 'routes'
import useDeviceSize from 'hooks/useDevice'
import LinkButton, { SimpleLinkButtonProps } from 'components/LinkButton'

type Props = SimpleLinkButtonProps & {
  responsive?: boolean
}

export default function CreateOrgButton({ responsive, ...props }: Props) {
  const { small } = useDeviceSize()
  const { t } = useTranslation()
  return (
    <LinkButton
      label={responsive && small ? false : t('cta.org.create-org')}
      title={t('cta.org.create-org')}
      {...props}
      href={routes.org.createOrg}
    />
  )
}
