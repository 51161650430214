/** @jsxImportSource @emotion/react */
import Link, { LinkProps } from 'next/link'
import React, { forwardRef, PropsWithoutRef, Ref } from 'react'
import { Button, ButtonExtendedProps } from 'grommet'

type LinkRef = HTMLButtonElement | HTMLAnchorElement
export type LinkButtonProps = Omit<ButtonExtendedProps, 'href'> &
  Pick<LinkProps, 'href' | 'as' | 'prefetch' | 'locale' | 'shallow'>
export type SimpleLinkButtonProps = PropsWithoutRef<Omit<LinkButtonProps, 'href'>>

const LinkButton = (
  { href, as, prefetch, locale, shallow, ...props }: LinkProps,
  ref: Ref<LinkRef>,
) => (
  <Link href={href} as={as} prefetch={prefetch} locale={locale} shallow={shallow} passHref>
    {/* @ts-expect-error Weird button ref typing */}
    <Button ref={ref} {...props} css={{ textAlign: 'center' }} />
  </Link>
)

export default forwardRef<LinkRef, LinkButtonProps>(LinkButton)
