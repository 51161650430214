import { useTranslation } from 'next-i18next'
import { PropsWithoutRef, useCallback } from 'react'
import { Button, ButtonExtendedProps } from 'grommet'

import { useAuth } from 'hooks/useAuth'
import { supabaseClient } from 'supabaseClient'

export default function LogoutButton(
  props: PropsWithoutRef<ButtonExtendedProps & JSX.IntrinsicElements['a']>,
) {
  const { t } = useTranslation()
  const { session, user } = useAuth()
  const handleLogout = useCallback(async () => await supabaseClient.auth.signOut(), [])

  if (!session || !user) {
    return null
  }

  return <Button primary size="small" label={t('cta.logout')} {...props} onClick={handleLogout} />
}
