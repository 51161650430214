/** @jsxImportSource @emotion/react */
import { Box, Text, ThemeContext, ThemeType } from 'grommet'
import { useTranslation } from 'react-i18next'

import { routes } from 'routes'
import { useAuth } from 'hooks/useAuth'
import Divider from 'components/Divider'
import useSidebar from 'hooks/useSidebar'
import useDeviceSize from 'hooks/useDevice'
import LinkButton from 'components/LinkButton'
import { dropHoverStyle } from 'util/ui-tables'
import LogoutButton from 'components/auth/LogoutButton'
import ImageFromStorage from 'components/storage/ImageFromStorage'

const theme: ThemeType = {
  button: {
    border: { radius: 'initial', color: 'transparent' },
    extend: `
      text-align: left;
      font-weight: normal;
    `,
  },
}

export default function DropdownProfileMenu() {
  const { user } = useAuth()
  const { selectedOrg } = useSidebar()
  const { t } = useTranslation()

  const { small, fontSize, buttonSize } = useDeviceSize()
  if (!user) return null
  return (
    <ThemeContext.Extend value={theme}>
      <Box
        gap="xsmall"
        direction="column"
        width={{
          min: '15rem',
          max: small ? undefined : 'medium',
          width: small ? 'large' : undefined,
        }}
        css={dropHoverStyle}
        pad={{ vertical: small ? 'large' : 'small' }}>
        <Box
          gap="small"
          align="center"
          justify="center"
          direction="column"
          margin={{ top: small ? 'large' : 'small' }}>
          <ImageFromStorage
            background="background-contrast"
            size="3rem"
            title={user.email}
            url={user?.user_metadata.avatar_url}
          />
          <Box direction="column" pad={{ horizontal: 'small' }}>
            <Text size={fontSize} textAlign="center" weight="bold">
              {user.user_metadata.full_name}
            </Text>
            <Text size="xsmall" color="gray" textAlign="center" wordBreak="break-all">
              {user.email}
            </Text>
          </Box>
        </Box>

        <Divider size="small" />

        <Box direction="column">
          <LinkButton
            primary
            size={buttonSize}
            href={routes.profile}
            label={t('nav.my-profile')}
            css={{ textAlign: 'left', padding: '.5rem 1.25rem' }}
            hoverIndicator="background-contrast"
          />

          <LinkButton
            primary
            size={buttonSize}
            href={selectedOrg?.domain ? routes.org.settings(selectedOrg.domain) : routes.settings}
            label={t('nav.account-settings')}
            css={{ textAlign: 'left', padding: '.5rem 1.25rem' }}
            hoverIndicator="background-contrast"
          />

          <LinkButton
            primary
            size={buttonSize}
            href={selectedOrg?.domain ? routes.org.billing(selectedOrg.domain) : routes.pricing}
            label={t('components.drop-profile-menu.billing')}
            css={{ textAlign: 'left', padding: '.5rem 1.25rem' }}
            hoverIndicator="background-contrast"
          />
        </Box>
        <Box />
        <LogoutButton primary size={buttonSize} hoverIndicator="background-contrast" />
      </Box>
    </ThemeContext.Extend>
  )
}
