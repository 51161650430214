import { CSSObject } from '@emotion/react'

export const boxShadow = '0px 8px 16px rgb(0 0 0 / 5%)'

export const headerHoverStyle: CSSObject = {
  transition: 'all .25s',
  background: 'rgb(255 255 255 / 95%)',
  backdropFilter: 'blur(3px)',
  boxShadow,
}

export const tableHoverStyle: CSSObject = {
  transition: 'all .25s',
  background: 'rgb(255 255 255 / 75%)',
  '&:hover': {
    background: 'white',
    boxShadow,
  },
}

export const dropHoverStyle: CSSObject = {
  transition: 'all .25s',
  '&:hover': {
    boxShadow,
  },
}
