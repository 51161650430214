/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { Box, DropButton } from 'grommet'

import { useAuth } from 'hooks/useAuth'
import ImageFromStorage from 'components/storage/ImageFromStorage'

import DropArrow from './DropArrow'
import DropdownProfileMenu from './DropdownProfileMenu'

export default function ProfileMenuButton() {
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  return (
    <Box background={open ? 'background-contrast-tr' : undefined} justify="center">
      <DropButton
        reverse
        gap="2px"
        alignSelf="center"
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={
          <ImageFromStorage
            size="28px"
            background="none"
            title={user?.email}
            url={user?.user_metadata.avatar_url}
          />
        }
        icon={<DropArrow size="20px" open={open} />}
        css={{ paddingLeft: '.65rem', paddingRight: '.5rem' }}
        dropContent={<DropdownProfileMenu />}
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropProps={{
          elevation: 'small',
          overflow: 'visible',
          margin: { top: '1rem' },
          round: { corner: 'bottom', size: 'xsmall' },
        }}
      />
    </Box>
  )
}
