/** @jsxImportSource @emotion/react */
import React from 'react'
import Head from 'next/head'
import { useTranslation } from 'react-i18next'
import { Box, BoxExtendedProps, BoxProps } from 'grommet'

import useFlags from 'hooks/useFlags'

import FooterNav from './FooterNav'
import HeaderNav from './HeaderNav'
import ValleyVideo from './background/ValleyVideo'

type LayoutProps = React.PropsWithChildren<{
  title?: string
  showVideo?: boolean
  showBackground?: boolean
  justify?: BoxProps['justify']
  mainProps?: BoxExtendedProps
}> &
  JSX.IntrinsicElements['div']

export default function Layout({
  title,
  children,
  showBackground = false,
  showVideo = false,
  justify = 'center',
  mainProps,
  ...props
}: LayoutProps) {
  const flags = useFlags()
  const { t } = useTranslation()
  return (
    <div
      css={{
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        ...(showBackground || flags.forest
          ? {
              backgroundImage: `url('/valley/valley-day/valley-day.svg')`,
              backgroundSize: 'cover',
            }
          : {}),
      }}
      {...props}>
      {(showVideo || flags.liveForest) && <ValleyVideo />}
      <Head>
        <title>{title ? `${t(title)} | Moodlight` : 'Moodlight'}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {flags.widget ? null : <HeaderNav />}

      <Box
        as="main"
        pad="none"
        flex="grow"
        align="center"
        overflow="auto"
        justify={justify}
        direction="column"
        {...mainProps}>
        {children}
      </Box>

      <FooterNav />
    </div>
  )
}
