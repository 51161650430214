import { Box, BoxExtendedProps } from 'grommet'
import { EdgeSizeType } from 'grommet/utils'

type Props = BoxExtendedProps & {
  size?: EdgeSizeType | string
}

export default function Divider({ size = 'large', ...rest }: Props) {
  return <Box height="1px" background="#ddd" margin={{ vertical: size }} {...rest} />
}
