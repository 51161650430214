/** @jsxImportSource @emotion/react */

export default function ValleyVideo() {
  return (
    <div css={{ position: 'fixed', zIndex: -1 }}>
      <video autoPlay muted loop css={{ width: '100vw' }}>
        <source src="/valley/valley-day/valley-day.mp4" type="video/mp4" />
      </video>
    </div>
  )
}
