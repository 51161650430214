/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { Box, Text, ThemeContext, ThemeType } from 'grommet'

import { useAuth } from 'hooks/useAuth'
import useSidebar from 'hooks/useSidebar'
import useDeviceSize from 'hooks/useDevice'
import { dropHoverStyle } from 'util/ui-tables'
import DisplayOrg from 'components/org/DisplayOrg'
import CreateOrgButton from 'components/org/CreateOrgButton'
import ImageFromStorage from 'components/storage/ImageFromStorage'

const theme: ThemeType = {
  button: {
    border: { radius: 'initial', color: 'transparent' },
    extend: `
      text-align: left;
      font-weight: normal;
    `,
  },
}

export default function DropdownOrgMenu() {
  const { user } = useAuth()
  const { t } = useTranslation()
  const { orgs, selectOrg, clearOrg } = useSidebar()

  const { small } = useDeviceSize()
  if (!user) return null
  return (
    <ThemeContext.Extend value={theme}>
      <Box
        gap="xsmall"
        direction="column"
        width={{
          min: '12rem',
          max: small ? undefined : 'medium',
          width: small ? 'large' : undefined,
        }}
        css={dropHoverStyle}
        pad={{ vertical: 'small' }}>
        <Box pad={{ horizontal: 'small' }}>
          <Text size="xsmall" color="gray" textAlign="center" margin={{ bottom: 'small' }}>
            Personal account:
          </Text>
          <Box
            gap="small"
            align="center"
            direction="row"
            pad={{ horizontal: 'small' }}
            onClick={() => clearOrg()}>
            <ImageFromStorage
              size="28px"
              background="none"
              title={user?.email}
              url={user?.user_metadata.avatar_url}
            />
            <Text size="small" weight="bold">
              {user.user_metadata.full_name ?? t('Your account')}
            </Text>
          </Box>

          <Text size="xsmall" color="gray" textAlign="center" margin={{ top: 'medium' }}>
            Organizations:
          </Text>
          {orgs.length > 0 &&
            orgs.map((org, index) => (
              <DisplayOrg
                org={org}
                key={index}
                pad={{ left: 'small' }}
                margin={{ vertical: 'small' }}
                onClick={() => selectOrg(org)}
              />
            ))}
          <CreateOrgButton size={small ? 'medium' : 'small'} margin={{ top: 'small' }} />
        </Box>
      </Box>
    </ThemeContext.Extend>
  )
}
