/** @jsxImportSource @emotion/react */
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Login, User } from 'grommet-icons'
import { useTranslation } from 'next-i18next'
import { Box, ThemeType, Header, ThemeContext } from 'grommet'

import { colors } from 'theme'
import { routes } from 'routes'
import { useAuth } from 'hooks/useAuth'
import useDeviceSize from 'hooks/useDevice'
import LinkButton from 'components/LinkButton'
import { headerHoverStyle } from 'util/ui-tables'
import MoodlightLogo from 'components/icon/MoodlightLogo'

import OrgMenuButton from './OrgMenuButton'
import ProfileMenuButton from './ProfileMenuButton'
import ListGroupsButton from '../group/nav/ListGroupsButton'
import CreateGroupButton from '../group/nav/CreateGroupButton'
import useSidebar from 'hooks/useSidebar'

export const headerHeight = '4rem'

const theme: ThemeType = {
  button: {
    border: { color: 'transparent' },
    default: { color: 'text' },
    hover: { color: 'accent-2' },
    active: {
      default: {
        color: 'accent-2',
        background: { color: 'transparent' },
      },
      primary: {
        color: 'accent-2',
        background: { color: 'transparent' },
      },
    },
    primary: {
      color: 'text',
      font: { weight: 'bold' },
    },
    extend: ({ primary }) => `
      font-weight: ${primary ? 'bold' : 'normal'};
    `,
  },
  avatar: {
    extend: `
      &:hover svg {
        stroke: ${colors['accent-2']};
      }
    `,
  },
}

export default function HeaderNav() {
  const { t } = useTranslation()
  const { small } = useDeviceSize()
  const { user } = useAuth()
  const { selectedOrg } = useSidebar()
  const router = useRouter()

  return (
    <ThemeContext.Extend value={theme}>
      <Header
        justify="center"
        fill="horizontal"
        css={[headerHoverStyle, { zIndex: 15, position: 'sticky', top: 0 }]}
        background={small ? 'white' : undefined}>
        <Box
          direction="row"
          justify="between"
          height={headerHeight}
          width={small ? '100vw' : '70vw'}>
          <BrandBox />
          <Box direction="row">
            {!small && (
              <>
                <LinkButton
                  size="small"
                  alignSelf="center"
                  href={routes.group.join}
                  active={router.pathname.includes(routes.group.join)}
                  label={t('nav.join-with-code')}
                />
                {user && (
                  <LinkButton
                    size="small"
                    alignSelf="center"
                    label={t('nav.reports')}
                    active={router.pathname.includes(routes.analytics)}
                    href={
                      selectedOrg?.domain
                        ? routes.org.analytics(selectedOrg.domain)
                        : routes.analytics
                    }
                  />
                )}
              </>
            )}
            {user && (
              <>
                <CreateGroupButton
                  responsive
                  size="small"
                  alignSelf="center"
                  active={router.pathname.includes(routes.group.create)}
                />
                <ListGroupsButton
                  responsive
                  size="small"
                  alignSelf="center"
                  active={router.pathname === routes.group.list}
                />
                <OrgMenuButton />
                <ProfileMenuButton />
              </>
            )}
            {!user && (
              <>
                <LinkButton
                  size="small"
                  alignSelf="center"
                  href={routes.login}
                  label={small ? undefined : t('cta.login')}
                  icon={small ? <Login /> : undefined}
                  active={router.pathname.includes(routes.login)}
                />
                <LinkButton
                  primary
                  size="small"
                  alignSelf="center"
                  href={routes.signup}
                  label={small ? undefined : t('cta.signup')}
                  icon={small ? <User /> : undefined}
                  active={router.pathname.includes(routes.signup)}
                />
              </>
            )}
          </Box>
        </Box>
      </Header>
    </ThemeContext.Extend>
  )
}

const BrandBox = () => {
  const { small } = useDeviceSize()
  return (
    <Box direction="row" align="center" margin={{ left: small ? 'medium' : undefined }}>
      <Link href={routes.home} passHref>
        <a title="Moodlight">
          <MoodlightLogo css={{ height: '2.5rem' }} />
        </a>
      </Link>
    </Box>
  )
}
