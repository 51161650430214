/** @jsxImportSource @emotion/react */
import { setCookie } from 'nookies'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Anchor, Box, Collapsible } from 'grommet'
import { useCallback, useMemo, useState } from 'react'

import useDeviceSize from 'hooks/useDevice'
import LinkAnchor from 'components/LinkAnchor'
import { trackEvent } from 'utils/useGTM'

export type Language = {
  code: string
  label: string
  active: boolean
}

export const LanguageChanger = () => {
  const router = useRouter()
  const { i18n } = useTranslation()
  const { small } = useDeviceSize()
  const [open, setOpen] = useState(false)
  const languages = useMemo<Language[]>(
    () => [
      { code: 'en', label: 'English', active: 'en' === i18n.language },
      { code: 'bg', label: 'Български', active: 'bg' === i18n.language },
      { code: 'es', label: 'Español', active: 'es' === i18n.language },
    ],
    [i18n.language],
  )
  const currLang = useMemo<Language>(
    () => languages.find((lang) => lang.active) || languages[0],
    [languages],
  )

  const changeLanguage = useCallback(
    (locale: string) => {
      // Change runtime translations
      i18n.changeLanguage(locale)
      trackEvent({
        event: 'ChangeLanguage',
        from: currLang.code,
        to: locale,
      })

      // Set locale cookie
      setCookie(null, 'NEXT_LOCALE', locale, { maxAge: 30 * 24 * 60 * 60, path: '/' })
    },
    [currLang.code, i18n],
  )

  return (
    <Box
      overflow="hidden"
      width={{ min: '5rem' }}
      fill={small && 'horizontal'}
      margin={{ vertical: 'small' }}>
      <Collapsible open={open}>
        <Box gap="small" align="center" direction="column" margin={{ vertical: 'small' }}>
          {languages
            .filter((lang) => !lang.active)
            .map(({ label, code, active }) => (
              <LinkAnchor
                key={code}
                size="14px"
                locale={code}
                color="dark-2"
                href={router.asPath}
                weight={active ? 600 : 'normal'}
                onClick={() => changeLanguage(code)}>
                {label}
              </LinkAnchor>
            ))}
        </Box>
      </Collapsible>
      <Anchor
        href="#"
        size="14px"
        color="dark-2"
        css={{ textAlign: 'center' }}
        margin={{ top: small ? 'small' : undefined }}
        onClick={() => setOpen(!open)}>
        {currLang.label}
      </Anchor>
    </Box>
  )
}
