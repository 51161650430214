import { IconProps, User } from 'grommet-icons'
import { AvatarExtendedProps, Spinner } from 'grommet'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { supabaseClient } from 'supabaseClient'
import { AvatarWrapper } from 'components/group/moodsList/AvatarWrapper'

export type Buckets = 'avatars'

type StorageImageParams = {
  url?: string | null
  bucket?: Buckets
  iconSize?: IconProps['size']
} & AvatarExtendedProps

export default function ImageFromStorage({
  url,
  bucket = 'avatars',
  iconSize,
  ...rest
}: StorageImageParams) {
  const isAbsoluteUrl = useMemo(() => url?.startsWith('https') || url?.startsWith('//'), [url])
  const [fileUrl, setFileUrl] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const downloadImage = useCallback(
    async (path: string) => {
      try {
        setLoading(true)
        const { data, error } = await supabaseClient.storage.from(bucket).getPublicUrl(path)
        if (error || !data) {
          throw error
        }
        setFileUrl(data.publicURL)
      } catch (error) {
        console.log('Error downloading image: ', error)
      } finally {
        setLoading(false)
      }
    },
    [bucket],
  )

  useEffect(() => {
    // Don't try to fetch absolute urls
    if (url && !isAbsoluteUrl) {
      downloadImage(url)
    }
  }, [downloadImage, url, isAbsoluteUrl])

  if (url && isAbsoluteUrl) {
    return <AvatarWrapper src={url} {...rest} />
  }

  if (fileUrl) {
    return <AvatarWrapper src={fileUrl} {...rest} />
  }

  return (
    <AvatarWrapper {...rest}>
      {loading ? <Spinner /> : <User pointerEvents="auto" size={iconSize} cursor="pointer" />}
    </AvatarWrapper>
  )
}
