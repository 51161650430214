/** @jsxImportSource @emotion/react */
import { Box, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

import { routes } from 'routes'
import useDeviceSize from 'hooks/useDevice'

import LinkAnchor from '../LinkAnchor'
import ExternalAnchor from '../ExternalAnchor'
import { LanguageChanger } from '../language/LanguageChanger'

export default function FooterNav() {
  const { t } = useTranslation()
  const { small, mobile } = useDeviceSize()
  return (
    <Box
      as="footer"
      css={
        mobile
          ? { flex: '0 0 auto' }
          : { position: 'absolute', bottom: 0, left: '22vw', right: '22vw' }
      }>
      <Box
        direction={small ? 'column' : 'row'}
        gap="large"
        align="center"
        fill={small ? 'horizontal' : undefined}
        pad={{ horizontal: 'large', bottom: small ? 'large' : undefined }}
        background={{ color: 'white', opacity: 'strong' }}
        css={{
          borderTopLeftRadius: '2rem',
          borderTopRightRadius: '2rem',
          margin: '0 auto',
        }}>
        <LanguageChanger />
        <Text size="xsmall" css={{ whiteSpace: 'nowrap' }}>
          {t('components.footer.made-with')}
          <span style={{ verticalAlign: 'middle', padding: '0 .35rem' }}>&#10084;&#65039;</span>
          {t('components.footer.by')}{' '}
          <ExternalAnchor color="dark-2" label="Camplight" href="https://camplight.net" />
        </Text>
        <LinkAnchor
          href={routes.privacy}
          label={t('components.footer.privacy-policy')}
          color="dark-2"
          size="xsmall"
          css={{ whiteSpace: 'nowrap' }}
        />
        <LinkAnchor
          href={routes.termsOfUse}
          label={t('components.footer.terms-of-use')}
          color="dark-2"
          size="xsmall"
          css={{ whiteSpace: 'nowrap' }}
        />
      </Box>
    </Box>
  )
}
