import React, { forwardRef, Ref } from 'react'
import Link, { LinkProps } from 'next/link'
import { Anchor, AnchorExtendedProps } from 'grommet'

type LinkRef = HTMLAnchorElement
export type LinkAnchorProps = Omit<AnchorExtendedProps, 'href'> &
  Pick<LinkProps, 'href' | 'as' | 'prefetch' | 'locale' | 'shallow'>

const LinkAnchor = (
  { href, as, prefetch, locale, shallow, ...props }: LinkProps,
  ref: Ref<LinkRef>,
) => (
  <Link href={href} as={as} prefetch={prefetch} locale={locale} shallow={shallow} passHref>
    <Anchor ref={ref} {...props} />
  </Link>
)

export default forwardRef<LinkRef, LinkAnchorProps>(LinkAnchor)
