import { PropsWithChildren } from 'react'
import { Avatar, AvatarExtendedProps } from 'grommet'

type Props = PropsWithChildren<AvatarExtendedProps>

export const AvatarWrapper = ({ children, ...rest }: Props) => {
  return (
    <Avatar background="brand" size="32px" {...rest}>
      {children}
    </Avatar>
  )
}
