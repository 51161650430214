import Link from 'next/link'
import { List } from 'grommet-icons'
import { PropsWithoutRef } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, ButtonExtendedProps } from 'grommet'

import { routes } from 'routes'
import useDeviceSize from 'hooks/useDevice'
import useSidebar from 'hooks/useSidebar'

export default function ListGroupsButton(
  props: PropsWithoutRef<ButtonExtendedProps> & { responsive?: boolean },
) {
  const { small } = useDeviceSize()
  const { selectedOrg } = useSidebar()
  const { t } = useTranslation()
  return (
    <Link
      href={selectedOrg?.domain ? routes.org.group.list(selectedOrg.domain) : routes.group.list}
      passHref>
      <Button
        icon={small ? <List /> : undefined}
        label={props.responsive && small ? false : t('nav.my-groups')}
        title={t('nav.my-groups')}
        {...props}
      />
    </Link>
  )
}
