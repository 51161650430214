import { useContext } from 'react'
import { DirectionType } from 'grommet/utils'
import { ButtonProps, ResponsiveContext } from 'grommet'

type Sizes = {
  size: string
  mobile: boolean
  xsmall: boolean
  small: boolean
  medium: boolean
  large: boolean
  direction: DirectionType
  buttonSize: ButtonProps['size']
  fontSize: 'small' | 'medium' | 'large'
  matchingSize: 'small' | 'large'
  matchingSizeSmall: 'small' | 'large'
}

export default function useDeviceSize(): Sizes {
  const size = useContext(ResponsiveContext)
  const small = size === 'small'
  const mobile = ['small', 'medium'].includes(size)
  return {
    size,
    mobile,
    small,
    xsmall: size === 'xsmall',
    medium: size === 'medium',
    large: size === 'large',
    direction: ['xsmall', 'small'].includes(size) ? 'column' : 'row',
    buttonSize: small ? 'large' : 'small',
    fontSize: small ? 'medium' : 'small',
    matchingSize: mobile ? 'large' : 'small',
    matchingSizeSmall: small ? 'large' : 'small',
  }
}
