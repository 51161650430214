import { PropsWithoutRef } from 'react'
import { NewWindow } from 'grommet-icons'
import { useTranslation } from 'next-i18next'

import { routes } from 'routes'
import useSidebar from 'hooks/useSidebar'
import useDeviceSize from 'hooks/useDevice'
import LinkButton, { SimpleLinkButtonProps } from 'components/LinkButton'

export default function CreateGroupButton(
  props: PropsWithoutRef<SimpleLinkButtonProps> & { responsive?: boolean },
) {
  const { small } = useDeviceSize()
  const { selectedOrg } = useSidebar()
  const { t } = useTranslation()
  return (
    <LinkButton
      icon={small ? <NewWindow /> : undefined}
      label={props.responsive && small ? false : t('cta.create-group')}
      title={t('cta.create-group')}
      {...props}
      href={selectedOrg?.domain ? routes.org.group.create(selectedOrg.domain) : routes.group.create}
    />
  )
}
